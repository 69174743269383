define("discourse/plugins/swapd-sponsoring/discourse/initializers/search-sponsoring", ["exports", "discourse/lib/plugin-api", "discourse-common/utils/decorators"], function (_exports, _pluginApi, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "swapd-sponsoring",
    initialize(container) {
      (0, _pluginApi.withPluginApi)("1.3.0", api => {
        // full page search
        api.modifyClass("component:search-result-entry", dt7948.p({
          pluginId: "swapd-sponsoring",
          classNameBindings: ['isSponsored:spon-result'],
          isSponsored() {
            return this?.post?.blurb?.startsWith('<span class="sponcont">');
          }
        }, [["method", "isSponsored", [(0, _decorators.default)("model.post.blurb")]]]));
      });
    }
  };
});